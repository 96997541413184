<template>
  <div class="container">
    <div
      class="positon-relative"
      v-scroll-reveal="{ delay: 50, distance: '20px' }"
    >
      <div class="shane_tm_title">
        <div class="title_flex">
          <div class="left">
            <span>Portfolio</span>
            <h3>Application Portfolio</h3>
          </div>
        </div>
      </div>
      <!-- End shane_tm_title -->
      <div class="portfolio_filter">
        <ul>
          <li>
            <a
              v-on:click="activetab = 1"
              :class="[activetab === 1 ? 'active' : '']"
              >All</a
            >
          </li>
          <!-- End All for tabcontent 1 -->
          <li>
            <a
              v-on:click="activetab = 2"
              :class="[activetab === 2 ? 'active' : '']"
              >Web</a
            >
          </li>
          <!-- End Vimeo tabcontent 2 -->
          <li>
            <a
              v-on:click="activetab = 3"
              :class="[activetab === 3 ? 'active' : '']"
              >Mobile</a
            >
          </li>
          <!-- End .Youtube tabcontent 3 -->
        </ul>
      </div>
      <!-- End .portfolio-filter -->
    </div>

    <div v-if="activetab === 1" class="tabcontent">
      <div class="portfolio_list">
        <ul class="gallery_zoom">
          <CoolLightBox :items="allItems" :index="index" @close="index = null">
          </CoolLightBox>
          <li v-for="(image, imageIndex) in allItems" :key="imageIndex">
            <div class="inner" @click="index = imageIndex">
              <div class="entry shane_tm_portfolio_animation_wrap">
                <img class="image" :src="image.thumb" alt="Portfolio" />
              </div>
              <!-- End .entry -->
              <div class="mobile_title">
                <h3>{{ image.portfolioName }}</h3>
                <span>{{ image.portfolioType }}</span>
              </div>
            </div>
          </li>
          <!-- End li -->
        </ul>
      </div>

      <!-- End .portfolio_list -->
    </div>
    <!-- End .tabcontent 1 -->

    <div v-if="activetab === 2" class="tabcontent">
      <div class="portfolio_list">
        <ul>
          <CoolLightBox
            :items="webItems"
            :index="index"
            @close="index = null"
          >
          </CoolLightBox>
          <li v-for="(image, imageIndex) in webItems" :key="imageIndex">
            <div class="inner" @click="index = imageIndex">
              <div class="entry tokyo_tm_portfolio_animation_wrap">
                <img class="image" :src="image.thumb" alt="Portfolio" />
              </div>
              <!-- End .entry -->
              <div class="mobile_title">
                <h3>{{ image.portfolioName }}</h3>
                <span>{{ image.portfolioType }}</span>
              </div>
            </div>
          </li>
          <!-- End li -->
        </ul>
        <!-- End .portfolio_list -->
      </div>
    </div>
    <!-- End .tabcontent 2 -->

    <div v-if="activetab === 3" class="tabcontent">
      <div class="portfolio_list">
        <ul>
          <CoolLightBox
            :items="appItems"
            :index="index"
            @close="index = null"
          >
          </CoolLightBox>
          <li v-for="(image, imageIndex) in appItems" :key="imageIndex">
            <div class="inner" @click="index = imageIndex">
              <div class="entry tokyo_tm_portfolio_animation_wrap">
                <img class="image" :src="image.thumb" alt="Portfolio" />
              </div>
              <!-- End .entry -->
              <div class="mobile_title">
                <h3>{{ image.portfolioName }}</h3>
                <span>{{ image.portfolioType }}</span>
              </div>
            </div>
          </li>
          <!-- End li -->
        </ul>
        <!-- End .portfolio_list -->
      </div>
    </div>
    <!-- End .tabcontent 3 -->

  </div>
</template>

<script>
export default {
  data() {
    return {
      activetab: 1,
      allItems: [
        {
          src: require("../../assets/img/portfolio/app-siabg.jpg"),
          thumb: require("../../assets/img/portfolio/app-siabg.jpg"),
          portfolioName: "Si ABG (1 Juta Bibit Gratis)",
          portfolioType: "Mobile App",
        },
        {
          src: require("../../assets/img/portfolio/app-slm.jpg"),
          thumb: require("../../assets/img/portfolio/app-slm.jpg"),
          portfolioName: "Sinarmas LDA Maritime Event",
          portfolioType: "Mobile App",
        },
        {
          src: require("../../assets/img/portfolio/web-simpp.jpg"),
          thumb: require("../../assets/img/portfolio/web-simpp.jpg"),
          portfolioName: "Sistem Managemen Persemaian Permanen",
          portfolioType: "Web App",
        },
        {
          src: require("../../assets/img/portfolio/web-slm.jpg"),
          thumb: require("../../assets/img/portfolio/web-slm.jpg"),
          portfolioName: "Sinarmas LDA Maritime Event",
          portfolioType: "Web App",
        },
        {
          src: require("../../assets/img/portfolio/web-map-jj.jpg"),
          thumb: require("../../assets/img/portfolio/web-map-jj.jpg"),
          portfolioName: "Maps JJpromotion",
          portfolioType: "Web App",
        },
        {
          src: require("../../assets/img/portfolio/web-benoa-square.jpg"),
          thumb: require("../../assets/img/portfolio/web-benoa-square.jpg"),
          portfolioName: "Benoa Square Bali",
          portfolioType: "Website",
        },
        {
          src: require("../../assets/img/portfolio/web-hidayatullah.jpg"),
          thumb: require("../../assets/img/portfolio/web-hidayatullah.jpg"),
          portfolioName: "Hidayatullah Badung",
          portfolioType: "Website",
        },
        {
          src: require("../../assets/img/portfolio/web-rsad-denpasar.jpg"),
          thumb: require("../../assets/img/portfolio/web-rsad-denpasar.jpg"),
          portfolioName: "Rumah Sakit Udayana",
          portfolioType: "Website",
        },
        {
          src: require("../../assets/img/portfolio/web-maju-jaya-group.jpg"),
          thumb: require("../../assets/img/portfolio/web-maju-jaya-group.jpg"),
          portfolioName: "Maju Jaya Group",
          portfolioType: "Website",
        },
        {
          src: require("../../assets/img/portfolio/web-triton-computer.jpg"),
          thumb: require("../../assets/img/portfolio/web-triton-computer.jpg"),
          portfolioName: "Triton Computer",
          portfolioType: "Website",
        },
      ],
      webItems: [
        {
          src: require("../../assets/img/portfolio/web-simpp.jpg"),
          thumb: require("../../assets/img/portfolio/web-simpp.jpg"),
          portfolioName: "Sistem Managemen Persemaian Permanen",
          portfolioType: "Web App",
        },
        {
          src: require("../../assets/img/portfolio/web-slm.jpg"),
          thumb: require("../../assets/img/portfolio/web-slm.jpg"),
          portfolioName: "Sinarmas LDA Maritime Event",
          portfolioType: "Web App",
        },
        {
          src: require("../../assets/img/portfolio/web-map-jj.jpg"),
          thumb: require("../../assets/img/portfolio/web-map-jj.jpg"),
          portfolioName: "Maps JJpromotion",
          portfolioType: "Web App",
        },
        {
          src: require("../../assets/img/portfolio/web-benoa-square.jpg"),
          thumb: require("../../assets/img/portfolio/web-benoa-square.jpg"),
          portfolioName: "Benoa Square Bali",
          portfolioType: "Website",
        },
        {
          src: require("../../assets/img/portfolio/web-hidayatullah.jpg"),
          thumb: require("../../assets/img/portfolio/web-hidayatullah.jpg"),
          portfolioName: "Hidayatullah Badung",
          portfolioType: "Website",
        },
        {
          src: require("../../assets/img/portfolio/web-rsad-denpasar.jpg"),
          thumb: require("../../assets/img/portfolio/web-rsad-denpasar.jpg"),
          portfolioName: "Rumah Sakit Udayana",
          portfolioType: "Website",
        },
        {
          src: require("../../assets/img/portfolio/web-maju-jaya-group.jpg"),
          thumb: require("../../assets/img/portfolio/web-maju-jaya-group.jpg"),
          portfolioName: "Maju Jaya Group",
          portfolioType: "Website",
        },
        {
          src: require("../../assets/img/portfolio/web-triton-computer.jpg"),
          thumb: require("../../assets/img/portfolio/web-triton-computer.jpg"),
          portfolioName: "Triton Computer",
          portfolioType: "Website",
        },
      ],
      appItems: [
       {
          src: require("../../assets/img/portfolio/app-siabg.jpg"),
          thumb: require("../../assets/img/portfolio/app-siabg.jpg"),
          portfolioName: "Si ABG (1 Juta Bibit Gratis)",
          portfolioType: "Mobile App",
        },
       {
          src: require("../../assets/img/portfolio/app-slm.jpg"),
          thumb: require("../../assets/img/portfolio/app-slm.jpg"),
          portfolioName: "Sinarmas LDA Maritime Event",
          portfolioType: "Mobile App",
        },
      ],
      index: null,
    };
  },
};
</script>

<style lang="scss" scoped></style>
