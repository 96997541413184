<template>
  <div class="map_wrap">
    <iframe
      id="gmap_canvas"
      src="https://maps.google.com/maps?q=jamur%20crispy%20arifah&t=&z=13&ie=UTF8&iwloc=&output=embed"
      frameborder="0"
      scrolling="no"
      marginheight="0"
      marginwidth="0"
    ></iframe>
  </div>
  <!-- End Google Map -->
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
