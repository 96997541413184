<template>
  <div class="home-two">
    <Header />
    <!-- End Header Section -->

    <Slider />
    <!-- End Slider Section -->

    <div class="shane_tm_section" id="about">
      <div class="shane_tm_about">
        <div class="container">
          <About />
        </div>
        <!--  End container -->
      </div>
    </div>
    <!-- End About Section -->

    <div class="shane_tm_section" id="portfolio">
      <div class="shane_tm_portfolio">
        <Portfolio />
      </div>
    </div>
    <!-- End Portfolio Section -->

    <Skills />
    <!-- End Skills Section -->

    <div class="shane_tm_section">
      <div class="shane_tm_partners">
        <div class="container">
          <div class="partners_inner">
            <Brand />
          </div>
        </div>
      </div>
    </div>
    <!-- End shane_tm_partners -->

    <CallToActions />
    <!-- End  call to actions -->

    <Footer />
    <!-- End  footer  -->
  </div>
</template>

<script>
import Header from "../../components/Header";
import Slider from "../../components/slider/Slider";
import About from "../../components/about/AboutTwo";
import Portfolio from "../../components/portfolio/PortfolioTwo";
import Skills from "../../components/skills/SkillsTwo";
import Brand from "../../components/Brand";
import CallToActions from "../../components/calltoactions/CallToActionsTwo";
import Footer from "../../components/Footer";
export default {
  components: {
    Header,
    Slider,
    About,
    Portfolio,
    Skills,
    Brand,
    CallToActions,
    Footer,
  },
};
</script>

<style lang="scss" scoped></style>
